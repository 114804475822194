.loginModal {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-flushpink);
  padding: 1%;
  max-height: 100vh;
  overflow-y: auto;
}

.registerModal {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-flushpink);
  padding: 1%;
  max-height: 100vh;
  overflow-y: auto;
}

.forgotPwModal {
  position: absolute;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-flushpink);
}

.confirmModal {
  position: absolute;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;
  background-color: var(--TLC-white);
  border-radius: 3px;
  box-shadow: 5px 5px 11px 0px rgba(0, 0, 0, 0.10);
}

.agreeModal {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;
  background-color: var(--TLC-white);
  border-radius: 3px;
  box-shadow: 5px 5px 11px 0px rgba(0, 0, 0, 0.10);
}

.input {
  height: 50px;
}

.input p {
  margin: 0;
  background-color: transparent;
}

label {
  margin-bottom: 0;
}

form {
  padding-right: 1%;
}

.card {
  width: 90%;
  margin: auto;
  padding-bottom: 2%;
  border: none;
  box-shadow: none;
  background: transparent;
}

label {
  margin-bottom: 0;
}

.input:hover {
  border-color: none;
}

.icon {
  color: var(--TLC-sharelnk);
}

.notice {
  font-size: 12px;
  color: #DCDCDC;
}

.notice a {
  color: #DCDCDC
}

.text {
  font-size: 16px;
  color: var(--TLC-drkgraytxt);
}

.error {
  font-size: 16px;
  height:10px;
  color: var(--TLC-pink)
}

.bold {
  color: var(--TLC-drkgraytxt);
  font-weight: 700;
}

hr {
  margin: 2% 0;
  border-top: 1px solid var(--TLC-blue-stroke);
}

.blueSq {
  height:  20px;
  width: 20px;
  border-radius:4px;
  background-color: var(--TLC-gray);
}

.mandatory {
  color: var(--TLC-gray);
}

.pwhelperError {
  color: var(--TLC-pink)
}

@media screen and (max-width: 400px) {
  .loginModal {
    width: 90%;
  }

  .forgotPwModal {
    width: 90%;
  }

  .card {
    max-height: 600px;
    padding: 4%;
    overflow-y: auto;
  }
}