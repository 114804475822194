.btnCheckout{
  border:1px solid #EA4F6F;
  background-color: #EA4F6F;
  color:#fff;
  height: 45px;
}

.btnCheckout:hover{
  border:1px solid #EA4F6F;
  background-color: #FCEDF0;
  color:#4E4E4E;
  height: 45px;
}

.productName {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

.productPrice {
  color: var(--TLC-bodytxt);
  font-size: 16px;
}

.productDetails {
  color: var(--drkgrytxt);
  font-size: 16px;
}

