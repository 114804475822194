.footer {
  /* margin: 0;
  left:0;
  bottom:0;
  right:0; */
  padding-top: 30px;
  padding-bottom: 30px;
  border: 0px solid grey;
}
.bgmenu{
  background: var(--TLC-webbg);
}

.footerBg {
  background-color: var(--TLC-webbg);
}

.footertext {
  font-family: 'EB Garamond', serif;
  size: 16px;
  padding:20px;
}
.copyright{
  color:#DCDCDC;
  font-size: 12px;
  display: block;
}


.copyright a {
  color: #DCDCDC;
  text-decoration: underline;
}

.logofooter{
  max-width: 186.33px;
}

.footerBrands {
  width: 40vw;
  min-width: 250px
}