/* .wvRsvpBtn{
    color:#E9E9E9 !important;
    background-color: #CCCCCC !important;
    width: 100%;
    border: 0px;
    padding:15px 0px;
    margin:5px 0px !important;
} */
.wvRsvpSelect {
  margin-bottom: 15px;
}

.wvRsvpBtn {
  font-family: "EB Garamond", serif;
  font-size: 14px;
  color: var(--TLC-drkgraytxt);
  background: var(--TLC-web-grey);
  border: 1px solid var(--TLC-blue-stroke);
  box-shadow: none;
}

.wvRsvpBtn:disabled {
  border: 1px solid #b8b8b8;
  box-shadow: none;
}

.wvRsvpBtn:hover {
  background: var(--TLC-sharelnk);
  box-shadow: none;
}

.guestRsvpModal {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-flushpink);
  padding: 1%;
  max-height: 100vh;
  overflow-y: auto;
}

.card {
  width: 90%;
  margin: auto;
  padding-bottom: 2%;
  border: none;
  box-shadow: none;
  background: transparent;
}

@media screen and (max-width: 400px) {
  .guestRsvpModal {
    width: 90%;
  }

  .card {
    max-height: 600px;
    padding: 4%;
    overflow-y: auto;
  }
}
