.input_file_upload {
  display: none;
}

.name {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

.relationship {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 16px;
  font-style: italic;
}