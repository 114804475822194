.divDonatePink {
  border: 1px solid #FFDFE5;
  background-color: var(--cart-action-button);
  padding: 20px;
}

.divDonateNormal {
  padding: 20px;
  width: max-content;
  margin: 0px auto;
}

.scanContainer {
  width: max-content;
  display: flex;
  gap: 3rem;
  align-items: center;
}

.donateDetails {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.donateDetails span {
  display: block;
}

.notes {
  margin-top: 3rem;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 550px) {

  .scanContainer {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0px;
    padding-bottom: 1.5rem;
  }

  .donateDetails p {
    margin: 0px;
  }

  .divDonateNormal {
    padding: 0px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
  }

  .notes {
    margin: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
    background-color: #F6F6F6;
  }
}