.container {
  padding-left: 2%;
  padding-right: 2%;
}

.nav {
  /* height: 60px; */
  background: none;
  box-shadow: none;
}

.btnStyleNavTabs a,
.navTabs a {
  font-family: 'EB Garamond', serif;
  font-size: 18px;
  color: var(--TLC-bodytxt);
  min-height: 48px;
}

.link {
  font-family: 'EB Garamond', serif;
  margin-right: 2%;
  text-decoration: none;
  color: var(--TLC-bodytxt);
  font-size: 14px;
}

.name {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 14px;
  padding: 0
}

.footerLink {
  font-family: 'EB Garamond', serif;
  text-decoration: none;
  color: var(--TLC-bodytxt);
  font-size: 16px;
  width: 50%;
}

.tabs {
  width: 100%;
  margin: auto;
  border-bottom: 2px solid var(--TLC-gray);
}

.divtabs a {
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  color: var(--TLC-bodytxt);
}

.logodiv {
  max-width: 214.78px;
}

.drawer {
  /* background-color: var(--TLC-flushpink); */
  background-color: rgba(0, 0, 0, 0.73);
  height: 150vh;
  /* border: 1px solid red; */
  width: 100vw;
}

.drawerItem {
  font-size: 20px;
  font-family: 'EB Garamond', serif;
  /* color: var(--TLC-bodytxt); */
  color: #FFFFFF;
}

.btnLogin {
  color: white;
  background: var(--TLC-gray);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.btnStyleTabs {
  width: 100%;
  margin: auto;
}

.btnStyleTabs a {
  border-style: solid;
  border-color: var(--TLC-gray);
  border-width: 2px;
}

.btnStyleTabs a:hover {
  background-color: var(--TLC-gray);
  color: white;
}

.divider {
  height: 2px;
  background-color: #545454;
  margin: 15px 50px;
}

@media screen and (max-width: 550px) {
  .logodiv {
    max-width: 118px;
  }

  .nav {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--TLC-flushpink);
    z-index: 99;
  }

  .navTab {
    width: 50%;
    align-items: start;
    color: #4e4e4e
  }

  .navGrid {
    width: 100%;
  }

  .tab {
    width: 40%;
    border-radius: 10px;
    font-weight: 700;
  }
}