.container {
  padding-left: 2%;
  padding-right: 2%;
}

.parent {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 48px;
  color: var(--TLC-bodytxt);
}

.scrollToTopBtn {
  position: fixed; 
  right: 25%;
  bottom: 25%;
  height: 20px;
  font-size: 3rem;
  z-index: 999;
  cursor: pointer;
  /* background-image: url('../../public/images/scroll-top.png');
  background-size: cover;
  width: 65px;
  height: 65px; */
}

.image {
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
  filter: blur(0);
}

@media only screen and (max-width: 1280px) {
  .container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding-left: 7%;
    padding-right: 7%;
  }
}