.container {
  background-color: var(--TLC-yellow);
  padding: 2%
}

.text {
  color: var(--TLC-drkgreytxt);
  font-size: 16px;
}

.link {
  cursor: pointer;
  color: var(--TLC-gray)
}

.link:hover {
  color: var(--TLC-gray)
}

.isflex {
  display: flex;
}

.mandatory {
  color: var(--TLC-gray);
}

.input {
  height: 40px;
}

.input p {
  margin: 0;
  background-color: transparent;
}

.tableheader {
  font-size: 16px ;
  font-weight: 400;
  color: var(--TLC-drkgraytxt);
}


/* GuestForm */
.col {
  width: 30%;
}

/*Guest card edit */
.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-flushpink);
}

@media only screen and (max-width: 1080px) {
  
}


@media only screen and (max-width: 768px) {
/* GuestForm */
.isflex {
  flex-direction: column;
}
.col {
  width: 100%;
}

.card {
  width: 80%;
}

}