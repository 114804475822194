.btnCheckout {
  border: 1px solid #ea4f6f;
  background-color: #ea4f6f;
  color: #fff;
  height: 45px;
  font-size: 14px;
}

.btnCheckout:hover {
  border: 1px solid #ea4f6f;
  background-color: #de3759;
  height: 45px;
}

.productName {
  font-family: "EB Garamond", serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

.productPrice {
  color: var(--TLC-bodytxt);
  font-size: 16px;
}

.productDetails {
  color: var(--drkgrytxt);
  font-size: 16px;
}

.orderAvatar {
  width: 30px;
  height: 30px;
  background-color: #edf2fc;
  color: #4e4e4e;
  font-size: 16px;
  font-weight: 400;
}

.mandatory {
  color: var(--TLC-gray);
}

.orderSummary {
  background-color: var(--TLC-web-grey);
}

.centerContainer {
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .spinButton {
    animation: App-logo-spin infinite 3s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}