.wvNotesImg{
    max-width: 100%;
    height: auto;
    padding-bottom:15px;
    display: flex;
    margin: auto;
}

@media screen and (max-width: 550px) {
    .wvNotesImg {
        border-radius: 5px;
        padding-bottom: 0;
    }
    
}