body {
  margin: 0;
  font-family: 'Kumbh Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--TLC-flushpink);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --TLC-white: #ffffff;
  --TLC-black: #000000;
  --TLC-webbg: #f2f2f2;
  --TLC-yellow: #FCFAED;
  --TLC-yellow-stroke: #FFF5B7;
  --TLC-drkgraytxt: #707070;
  --TLC-gray: #545454;
  --checkout-hover-color: #de3759;
  --TLC-blue: #edf2fc;
  --TLC-blulnk: #6397ff;
  --TLC-sharelnk:  #cbdcfd;
  --TLC-blue-stroke: #B7CAEF;
  --TLC-bodytxt: #4e4e4e;
  --TLC-pink:  #EA4F6F;
  --TLC-flushpink: #FCEDF0;
  --TLC-web-grey: #CCCCCC;
}

#index p {
  text-align: end;
  padding-right: 0;
  margin: 0;
}

.text {
  font-size: 18px
}

.textBold {
  font-size: 18px;
  font-weight: 700;
  color: var(--TLC-drkgraytxt);
}

.sectionTitle {
  font-family: 'EB Garamond', serif;
  font-weight: 700;
  color: var(--TLC-drkgraytxt);
  font-size: 20px;
}

.sectionTitleAbout {
  font-weight: 400;
  color: var(--TLC-drkgraytxt);
  font-size: 20px;
}

.sectionPara {
  color: var(--TLC-drkgraytxt);
  font-size: 18px;
}

.sectionText {
  color: var(--TLC-drkgraytxt);
  font-size: 18px;
}

.sectionText1 {
  color: var(--TLC-drkgraytxt);
  font-size: 16px;
}

.sectionText2 {
  color: var(--TLC-drkgraytxt);
  font-size: 14px;
}

.img {
  max-width: 100%;
  height: auto;
}

.hr {
  border-bottom: 2px solid var(--TLC-gray);
}

.link {
  cursor: pointer;
  color: var(--TLC-gray);
  font-size: 16px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type=time]::-webkit-datetime-edit-hour-field:focus,
input[type=time]::-webkit-datetime-edit-minute-field:focus,
input[type=time]::-webkit-datetime-edit-second-field:focus,
input[type=time]::-webkit-datetime-edit-ampm-field:focus {
  background-color: var(--TLC-gray);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button {
  display: none;
}

input[type="number"] {
  -moz-appearance: number-input; /* For Firefox */
  -webkit-appearance: number-input; /* Forces appearance on WebKit browsers */
  appearance: number-input; /* Standard */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: auto;
  margin: 0;
}