.flexContainer {
  max-height: 640px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.execRFM {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .flexContainer {
    max-height: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}