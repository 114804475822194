.showInMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .showInWeb {
        display: none;
    }

    .showInMobile {
        display: block;
    }
}