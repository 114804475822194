/* .wvDivTitle{
    padding: 10px 0px;
} */
.wvProfile{
    font-weight: bold;
    font-family: 'EB Garamond', serif;
    padding:0;
    margin:0;
    line-height: normal;
}
.wvText{
    font-size: 24px;
    font-family: 'EB Garamond', serif; 
    line-height: normal;
}

.avatar {
    width: 138px;
    height: 138px;
}

.avatarCenter {
    width: 138px;
    height: 138px;
    margin-top: -69px;
    z-index: 111;
}

@media only screen and (max-width: 768px) {
    .wvText{
        font-size: 18px;
        font-family: 'EB Garamond', serif; 
        line-height: normal;
    }

    .avatar {
        width: 80px;
        height: 80px;
    }

    .avatarCenter {
        width: 80px;
        height: 80px;
        margin-top: -40px;
        z-index: 111;
    }

    .profileGrid {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {
    .avatarCenter {
        width: 180px;
        height: 180px;
        margin-top: -90px;
        z-index: 111;
    }
}