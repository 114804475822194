.btnPrimary {
  height: 45px;
  color: var(--TLC-white);
  background: var(--TLC-gray);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnSecondary {
  height: 45px;
  color: var(--TLC-bodytxt);
  background: var(--TLC-white);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnLogin {
  height: 45px;
  width: 108px;
  color: white;
  background: var(--TLC-gray);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnLogin:hover {
  box-shadow: none;
  background: var(--TLC-web-grey);
  border: 1px solid var(--TLC-web-grey);
  color: var(--TLC-gray);
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .btnLogin {
    width: 108px;
    margin-top: 10px;
  }
}

.btnPrimary:hover,
.btnSecondary:hover {
  box-shadow: none;
  background: var(--TLC-gray);
  background-color: var(--TLC-gray);
  color: white !important;
  border-radius: 3px;
}

.btnDisabled {
  opacity: 0.5;
  color: var(--TLC-bodytxt);
  background: var(--TLC-web-grey);
  border: 1px solid var(--TLC-web-grey);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnIcon {
  color: var(--TLC-web-grey);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnTransparent {
  color: var(--TLC-bodytxt);
  border: 1px solid var(--TLC-web-grey);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
}

.btnCart {
  box-shadow: none;
  border: 1px solid var(--TLC-pink);
  background-color: var(--TLC-flushpink);
  border-radius: 3px;
  color: var(--TLC-bodytxt);
  ;
}

.btnCart:hover {
  box-shadow: none;
  background: var(--TLC-pink);
  border: 1px solid var(--TLC-pink);
  border-radius: 3px;
  border: none
}

.btnAccept {
  font-family: 'EB Garamond', serif;
  font-size: 18px;
  color: var(--TLC-drkgraytxt);
  background: var(--TLC-web-grey);
  border: 1px solid var(--TLC-gray);
  box-shadow: none;
  cursor: pointer;
}

.btnLink {
  border: none;
  color: var(--TLC-gray);
  text-decoration: underline;
}

.btnLink:hover {
  box-shadow: none;
  background: none;
  text-decoration: underline;
}

@media screen and (max-width: 550px) {
  .btnPrimary {
    border-radius: 10px;
  }

  .btnSecondary {
    border-radius: 10px;
    border: 2px solid var(--TLC-gray);
  }
}