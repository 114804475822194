.container {
  background: #FCFAF8;
  border: 1px solid var(--TLC-web-grey);
  margin-top: 2%;
  padding: 1%
}

.container2 {
  margin-top: 2%;
  padding: 1%
}

.isflex {
  display: flex;
}

.col {
  display: flex;
  width: 40%;
}

.label {
  padding-bottom: 1%;
  color: var(--TLC-drkgraytxt);
}

.input {
  background-color: white;
}

.inputLabel {
  font-size: 16px;
  font-weight: bold;
  width: 80px;
  white-space: break-spaces;
}

.inputMarginTop {
  margin-top: 2%
}

.flexCol {
  display: contents;
  flex-direction: column;
}

.listContainer {
  border-top: 2px solid var(--TLC-blue-stroke);
  border-bottom: 2px solid var(--TLC-blue-stroke);
}

.listItem {
  border-bottom: 1px solid var(--share-border);
}

.listImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 725px) {
  .isflex {
    flex-direction: column;
  }

  .col {
    flex-direction: column;
  }

  .inputLabel {
    margin: 0;
  }
}