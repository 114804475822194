.wvDetTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--TLC-drkgraytxt);
  padding-top: 0px !important;
}

.wvTitle {
  font-size: 32px;
  font-family: "EB Garamond", serif;
  padding: 0 0 15px;
  margin: 0;
}

.label {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

.labelWithPadding {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

.text {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

.links {
  margin-right: 2%;
  color: var(--TLC-gray);
  font-size: 16px;
}

.upcomingLink {
  font-size: 18px;
  color: var(--TLC-web-grey);
  text-decoration: underline;
}

.streamUrl {
  font-size: 18px;
  color: var(--TLC-bodytxt);
  text-decoration: underline;
}

.virtual {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

.loginToView {
  cursor: pointer;
  font-size: 18px;
  color: var(--TLC-web-grey);
  text-decoration: underline;
}

.underLine {
  text-decoration: underline;
}

.videoURL {
  width: 20%;
  padding-left: 0px !important;
}

.container {
  border: solid 2px var(--TLC-gray);
}

.copyContainer {
  margin-left: -12px;
  margin-top: 1rem;
}

.itemLabel {
  display: flex;
}

.icon {
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .videoURL {
    width: 10%;
    padding-left: 0px !important;
  }

  .icon {
    margin-right: 0px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    border: solid 2px var(--TLC-web-grey);
    border-radius: 10px;
    margin-top: 20px;
  }

  .copyContainer {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }

  .label {
    text-align: end;
  }

  .itemLabel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .icon {
    margin-right: 0px;
  }

  .itemLabel2 {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 10px;
    margin-right: 10px !important;
  }

  .labelInfo {
    font-size: 18px;
    color: var(--TLC-bodytxt);
    padding-left: 10px !important;
  }

  .text {
    padding-left: 10px !important;
  }

  .virtual {
    padding-left: 6px;
  }

  .labelWithPadding {
    text-align: end;
  }

  .labelWithPaddingLive {
    font-size: 18px;
    color: var(--TLC-bodytxt);
  }
}
