.buttonTxt {
    margin-right: 5px;
}

.buttonRow {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 768px) {
    .buttonRow {
      flex-direction: column;
    }
  }