.header {
  font-family: 'EB Garamond', serif;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 600;
}

.card {
  border-top: 1px solid var(--TLC-blue-stroke);
  border-bottom: 1px solid var(--TLC-blue-stroke)
}

.label {
  font-size: 16px;
}

.name {
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--TLC-bodytxt)
}

.msgDate {
  font-size: 14px;
  color: var(--TLC-drkgraytxt);
}

.borderBottom {
  border-bottom: 2px solid var(--TLC-gray);
}

.chip {
  background-color: var(--TLC-flushpink);
  border: 1px solid var(--TLC-pink)
}