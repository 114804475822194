.btnCheckout {
  border: 1px solid #EA4F6F;
  background-color: #EA4F6F;
  color: #fff;
  height: 45px;
  font-size: 14px;
}

.btnCheckout:hover {
  border: 1px solid #EA4F6F;
  background-color: #DE3759;
  height: 45px;
}

.productName {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

.productPrice {
  color: var(--TLC-bodytxt);
  font-size: 16px;
}

.productDetails {
  color: var(--drkgrytxt);
  font-size: 16px;
}

.loginButton {
  width: 120px;
  color: white;
  background: var(--TLC-gray);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.loginButton:hover {
  box-shadow: none;
  background: var(--TLC-gray);
  border-radius: 3px;
}

.orderAvatar {
  width: 30px;
  height: 30px;
  background-color: #EDF2FC;
  color: #4E4E4E;
  font-size: 16px;
  font-weight: 400;
}

.numberGroup {
  height: 45px;
  border: 1px solid;
  border-color: #CCCCCC;
  box-shadow: none;
  background-color: #f8f4f0;
}