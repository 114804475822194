#file_upload {
  height: 120px;
  max-width: 100%;
  text-align: center;
  position: relative;
  border: 1px solid var(--TLC-yellow-stroke);
  background-color: var(--TLC-yellow);
}

#csv_upload {
  height: 120px;
  max-width: 100%;
  text-align: center;
  position: relative;
  border: 1px solid var(--TLC-yellow-stroke);
  background-color: var(--TLC-yellow);
}

.imagePreview {
  display: flex;
  align-items:center;
  justify-content:center; 
  overflow: hidden;
}

.input_file_upload {
  display: none;
}

.uploadBox {
  /* display: flex; */
  height: 120px;
  margin: auto;
  align-items:center;
  justify-content:center;
}

.label_file_upload {
  /* width: 200px;
  text-align: center;
  background-color: transparent;
  color: var(--TLC-bodytxt);
  border: 1px solid var(--TLC-blue-stroke);
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem; */
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: var(--TLC-gray);
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.drag_active {
  opacity: 0.5;
}

#drag_file_element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.link {
  cursor: pointer;
  color: var(--TLC-gray);
  text-decoration: underline;
  font-size: 16px;
}

.fileBox {
  font-size: 16px;
  margin-top: 5px;
  color: #CCCCCC;
  font-family: "Kumbh Sans";
}