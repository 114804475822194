.btnSave {
  background-color: var(--TLC-gray);
  border-color: var(--TLC-gray);
  color: white;
  height: 45px;
}

.btnSave:hover {
  background-color: white;
  border-color: var(--TLC-gray);
  color: var(--TLC-gray);
}

td {
  border: none !important;
}

.productName {
  font-family: "EB Garamond", serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
  padding: 0;
  word-break: break-word;
}

.productPrice {
  color: var(--TLC-bodytxt);
  font-size: 16px;
  padding: 0;
  word-break: break-word;
}

.productDetails {
  color: var(--drkgrytxt);
  font-size: 16px;
  padding: 0;
}

.wvProfile {
  font-weight: bold;
  font-family: "EB Garamond", serif;
  padding: 0;
  margin: 0;
  line-height: normal;
}

.wvText {
  font-size: 16px;
  font-family: "EB Garamond", serif;
  line-height: normal;
}

.avatar {
  width: 60px;
  height: 60px;
}

.icon {
  color: var(--TLC-sharelnk);
}

.logoutText {
  font-family: "EB Garamond", serif;
  font-weight: 700;
  font-size: 20px;
  color: var(--TLC-drkgraytxt);
}

.returnText {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

@media only screen and (max-width: 768px) {
  .wvText {
    font-size: 18px;
    font-family: "EB Garamond", serif;
    line-height: normal;
  }

  .avatar {
    width: 60px;
    height: 60px;
  }
}

.btnView {
  width: 240px;
  height: 45px;
  color: var(--TLC-bodytxt);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .btnView {
    width: 100%;
  }
}

.btnView:hover {
  box-shadow: none;
  background: var(--TLC-web-grey);
  border-radius: 3px;
}

.btnBack {
  background-color: #edf2fc;
  border-color: #b7caef;
  color: #4e4e4e;
  height: 30px;
}

.btnBack:hover {
  background-color: #b7caef;
  border-color: #b7caef;
}

.tableCell {
  vertical-align: baseline;
  padding: 8px 0px;
}

.divtabs {
  border-bottom: 1px solid var(--TLC-web-grey);
}

@media screen and (max-width: 550px) {
  .divtabs {
    width: 50%;
  }
}