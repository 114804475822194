.container {
  height: 100vh; /* Magic here */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.pwhelper {
  font-size: 14;
  color: var(--TLC-drkgraytxt);
}

.pwhelper ul {
  margin-top: 0;
  padding-left: 3%;
}

.mandatory {
  color: var(--mandatory-fields);
}

.input p {
  margin: 0;
  color: var(--TLC-pink) !important;
  background-color: transparent;
}

.error {
  margin-top:0;
  padding-bottom: 16px;
  height:10px;
  font-size: 16px;
  color: var(--TLC-pink)
}

.link {
  color: var(--TLC-gray);
  text-transform: none;
}

.card {
  padding: 2%;
  border: none;
  box-shadow: none;
  background: transparent;
}

.login {
  font-family: 'EB Garamond', serif;
  font-size: 32px;
  font-weight: 700;
}

.icon {
  color: var(--TLC-sharelnk);
}

.notice {
  font-size: 12px;
  color: #DCDCDC;
}

.notice a {
  color: #DCDCDC
}