.btnCart {
  /* border: 1px solid #ea4f6f; */
  background-color: #545454;
  font-size: 14px;
  height: 45px;
  color: white;
}
.btnCart:hover {
  color: #fff !important;
  border: 1px solid #ea4f6f;
  background-color: #de3759;
}

.productName {
  font-family: "EB Garamond", serif;
  color: var(--TLC-bodytxt);
  font-size: 18px;
}

.productPrice {
  color: var(--TLC-bodytxt);
  font-size: 16px;
}

.productDetails {
  color: var(--drkgrytxt);
  font-size: 18px;
  word-break: break-all;
  white-space: normal;
  height: 70px;
}

.productDetails p {
  height: 70px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .productDetails {
    display: none;
  }
}

.singleProductDetails {
  color: var(--drkgrytxt);
  font-size: 18px;
  word-break: break-all;
  white-space: normal;
  height: 70px;
}

.singleProductDetails p {
  height: 70px;
  overflow: hidden;
}

.chevronLeft {
  margin-left: 40px;
  margin-bottom: 100px;
  color: #cccccc;
  background-color: #ffffff;
  font-size: 30px;
  width: 38px;
  height: 38px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  box-shadow: 1px 1px;
}

.chevronRight {
  margin-right: 40px;
  margin-bottom: 100px;
  color: #cccccc;
  background-color: #ffffff;
  font-size: 30px;
  width: 38px;
  height: 38px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  box-shadow: 1px 1px;
}

.pagination {
  align-items: end;
  padding-top: 30px;
}

.carouselImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.listImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.numberGroup {
  height: 45px;
  border: 1px solid;
  border-color: #cccccc;
  box-shadow: none;
  background-color: #f8f4f0;
}
