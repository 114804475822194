.wvDivShare{
    padding: 10px 0px 15px;
}
.wvShareText{
    padding-left:20px;
    font-size: 16px;
    color:#CBDCFD;
}
.wvShareSpan{
    padding-bottom: 10px;
    text-decoration: underline;
}
.wvShareLink{
    padding-left: 5px;
}
.wvShareLinkText{
    font-family: 'Kumbh Sans', sans-serif;
    text-decoration: underline;
}

.btnLink {
    position: relative;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    margin-bottom: 0px;
    margin-top: 0px;
}

